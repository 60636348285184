import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FetchResult } from 'apollo-link';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import { Moment } from 'moment';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';

import { AFormFieldRow } from 'app/components/atoms/AFormFieldRow/AFormFieldRow';
import { ALoading } from 'app/components/atoms/ALoading/ALoading';
import { ATextLight } from 'app/components/atoms/ATextLight/ATextLight';
import { ACheckboxField } from 'app/components/deprecated/ACheckboxField/ACheckboxField';
import { ADateField } from 'app/components/deprecated/ADateField/ADateField';
import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { ARadioField } from 'app/components/deprecated/ARadioField/ARadioField';
import { ASelectCouponField } from 'app/components/deprecated/ASelectCouponField/ASelectCouponField';
import { ASelectProductField } from 'app/components/deprecated/ASelectProductField/ASelectProductField';
import { theme } from 'app/styles/theme';
import { PapiProductType } from 'app/types/generated/globalTypes';
import {
  PlanPurchaseCreate_Mutation,
  PlanPurchaseCreate_MutationVariables,
} from 'app/types/generated/PlanPurchaseCreate_Mutation';
import {
  PlanPurchaseCreate_Mutation_FollowOn,
  PlanPurchaseCreate_Mutation_FollowOnVariables,
} from 'app/types/generated/PlanPurchaseCreate_Mutation_FollowOn';
import {
  PlanPurchaseCreate_Query,
  PlanPurchaseCreate_QueryVariables,
} from 'app/types/generated/PlanPurchaseCreate_Query';
import { displayErrors } from 'app/utils/app';
import { disablePastStartDates } from 'app/utils/datetime';
import { isValidPaymentCard } from 'app/utils/member';
import { getPlanPaymentOptions, isCurrentPlan } from 'app/utils/plan';
import {
  getEligiblePlansForPurchase,
  getPlanPurchaseAmountDue,
  getPlanPurchaseStartTimeAPIInput,
  getPlanPurchaseSummary,
} from 'app/utils/planPurchase';
import { STATUS_MESSAGE } from 'constants/message';

import {
  PLAN_PURCHASE_CREATE_MUTATION,
  PLAN_PURCHASE_CREATE_MUTATION_FOLLOW_ON,
  PLAN_PURCHASE_CREATE_QUERY,
  PLAN_PURCHASE_CREATE_REFETCH,
} from './query';

// Types & constants ////////////////////////////////
interface Props {
  icon?: boolean;
  openText?: ReactNode;
  personID: string;
}

enum FollowOnOption {
  NewPlan = 'new',
  None = 'none',
  SamePlan = 'same',
}

/** Form to enroll a member in a care plan ("plan purchase" in the db) */
const PlanPurchaseCreate: FC<Props> = ({
  icon,
  openText = 'Add care plan',
  personID,
}) => {
  const { data, loading: queryLoading } = useQuery<
    PlanPurchaseCreate_Query,
    PlanPurchaseCreate_QueryVariables
  >(PLAN_PURCHASE_CREATE_QUERY, { variables: { personID } });

  const [
    createPlanPurchase,
    { loading: createPlanPurchaseLoading },
  ] = useMutation<
    PlanPurchaseCreate_Mutation,
    PlanPurchaseCreate_MutationVariables
  >(PLAN_PURCHASE_CREATE_MUTATION, {
    refetchQueries: [
      {
        query: PLAN_PURCHASE_CREATE_REFETCH,
        variables: { personID },
      },
    ],
  });
  const [createFollowOn, { loading: createFollowOnLoading }] = useMutation<
    PlanPurchaseCreate_Mutation_FollowOn,
    PlanPurchaseCreate_Mutation_FollowOnVariables
  >(PLAN_PURCHASE_CREATE_MUTATION_FOLLOW_ON, {
    refetchQueries: [
      {
        query: PLAN_PURCHASE_CREATE_REFETCH,
        variables: { personID },
      },
    ],
  });

  const assignedCenter = data?.member?.center;
  const memberPlans = data?.member?.allPlans?.edges || [];

  const hasNonEndingPlan =
    memberPlans.filter(({ node }) => node.endTime === null).length > 0;
  const sortedPlans =
    memberPlans &&
    sortBy(memberPlans, (plan) => DateTime.fromISO(plan.node.startTime));
  const latestPlan =
    sortedPlans && sortedPlans.length > 0
      ? sortedPlans[sortedPlans.length - 1].node
      : undefined;

  const onOpen = (): void => {
    if (!data || !data.member || hasNonEndingPlan || !assignedCenter) {
      return;
    }
    if (!data.member.dateOfBirth) {
      message.warn(STATUS_MESSAGE.planPurchaseCreate.warning.noMemberAge, 5);
    }
    if (
      data.member.paymentCards.edges.filter(({ node }) =>
        isValidPaymentCard(node)
      ).length < 1
    ) {
      message.warn(STATUS_MESSAGE.planPurchaseCreate.warning.noValidPayment, 5);
    }
  };

  const onSave = async (
    input: { [K in keyof typeof fields]: any },
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      if (!assignedCenter) {
        throw new Error(STATUS_MESSAGE.planPurchaseCreate.error.noCenter);
      }

      let response:
        | FetchResult<PlanPurchaseCreate_Mutation>
        | FetchResult<PlanPurchaseCreate_Mutation_FollowOn>
        | void;
      if (input.addAsFollowOn) {
        response = await createFollowOn({
          variables: {
            input: {
              centerID: assignedCenter.id,
              couponSlug: input.couponSlug,
              // See showAddAsFollowOn for why we use latest plan to attach follow-on to
              currentPurchaseID: latestPlan!.id,
              planID: input.planID,
            },
          },
        });
      } else {
        response = await createPlanPurchase({
          variables: {
            input: {
              centerID: assignedCenter.id,
              couponSlug: input.couponSlug,
              followOnPurchase:
                !input.product.includes('WAITLIST') &&
                (input.followOnPlanID ||
                  input.followOnOption === FollowOnOption.SamePlan)
                  ? {
                      centerID: assignedCenter.id,
                      planID: input.followOnPlanID || input.planID,
                    }
                  : undefined,
              payorID: personID,
              personID,
              planID: input.planID,
              startTime: getPlanPurchaseStartTimeAPIInput(
                DateTime.fromJSDate((input.startTime as Moment).toDate()),
                assignedCenter.timezone
              ),
            },
          },
        });
      }

      if (response?.data) {
        message.success(STATUS_MESSAGE.planPurchaseCreate.success);
        shouldFinish();
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.planPurchaseCreate.error.general);
      shouldFinish(false);
    }
  };

  return (
    <AForm
      disableOpen={
        !data || !data.member
          ? { message: <ALoading color="white" size="small" /> }
          : !assignedCenter
          ? { message: STATUS_MESSAGE.planPurchaseCreate.error.noCenter }
          : hasNonEndingPlan
          ? { message: STATUS_MESSAGE.planPurchaseCreate.error.neverendingPlan }
          : undefined
      }
      icon={icon ? { tooltipText: 'Add care plan', type: 'plus' } : undefined}
      loading={queryLoading || !data || !data.member || !data.plans}
      onOpen={onOpen}
      onSave={onSave}
      openAs="modal"
      openText={openText}
      saveText="Add"
      saving={createFollowOnLoading || createPlanPurchaseLoading}
      savingText="Adding"
      title="New care plan"
    >
      {(form) => {
        const coupons = data?.coupons?.edges || [];
        const plans = data?.plans?.edges || [];
        const eligiblePlans =
          (data?.member &&
            getEligiblePlansForPurchase(
              data.member,
              plans.map(({ node }) => node)
            ).filter(
              (plan) => plan.product.type !== PapiProductType.EXTENSION
            )) ||
          [];

        // Only show follow-on option if member has current plan with an
        // end time and no additional plans in the future (except extensions).
        // When follow-on is enabled, the latest plan is guaranteed to be the
        // current plan or an extension off the current plan
        const showAddAsFollowOn =
          !hasNonEndingPlan &&
          memberPlans.find(({ node }) => isCurrentPlan(node)) &&
          memberPlans.filter(
            ({ node }) =>
              DateTime.fromISO(node.startTime) > DateTime.local() &&
              node.plan.product.type !== PapiProductType.EXTENSION
          ).length === 0;

        // Clear follow-on selections when "Add as follow-on to latest plan" is checked
        const addAsFollowOnChecked =
          showAddAsFollowOn &&
          (form.getFieldValue(fields.addAsFollowOn.name) ||
            // Value is undefined when field first renders
            form.getFieldValue(fields.addAsFollowOn.name) === undefined);
        const onAddAsFollowOnChange = (e: CheckboxChangeEvent): void => {
          if (e.target.checked === true) {
            form.setFieldsValue({
              [fields.followOnOption.name]: undefined,
              [fields.followOnProduct.name]: undefined,
              [fields.followOnPlanID.name]: undefined,
            });
          }
        };

        // Clear all plan selection fields when original product changes
        const selectedProduct = form.getFieldValue(fields.product.name);
        const onProductChange = (): void =>
          form.setFieldsValue({
            [fields.planID.name]: undefined,
            [fields.followOnOption.name]: undefined,
            [fields.followOnProduct.name]: undefined,
            [fields.followOnPlanID.name]: undefined,
          });

        // Set default follow-on or clear follow-on fields when plan payment changes
        const selectedPlan = eligiblePlans.find(
          (plan) => plan.id === form.getFieldValue(fields.planID.name)
        );
        const onPlanChange = (e: RadioChangeEvent): void => {
          const selectedPlan = eligiblePlans.find(
            (plan) => plan.id === e.target.value
          )!;
          const defaultFollowOnPlan = selectedPlan.defaultFollowOnPlan;
          if (!defaultFollowOnPlan) {
            form.setFieldsValue({
              [fields.followOnOption.name]: FollowOnOption.None,
            });
          } else if (defaultFollowOnPlan.id === selectedPlan.id) {
            form.setFieldsValue({
              [fields.followOnOption.name]: FollowOnOption.SamePlan,
            });
          } else {
            form.setFieldsValue({
              [fields.followOnOption.name]: FollowOnOption.NewPlan,
              [fields.followOnProduct.name]: defaultFollowOnPlan.product.type,
              [fields.followOnPlanID.name]: defaultFollowOnPlan.id,
            });
          }
        };

        const selectedCoupon =
          form.getFieldValue(fields.couponSlug.name) &&
          (coupons
            .map(({ node }) => node)
            .find(
              (coupon) =>
                coupon.slug === form.getFieldValue(fields.couponSlug.name)
            ) ||
            memberPlans
              .map((plan) => plan.node.coupon)
              .find(
                (coupon) =>
                  !!coupon &&
                  coupon.slug === form.getFieldValue(fields.couponSlug.name)
              ));

        // Reset follow-on product & payment when follow-on option changes
        const selectedFollowOnOption = form.getFieldValue(
          fields.followOnOption.name
        );
        const onFollowOnOptionChange = (): void =>
          form.setFieldsValue({
            [fields.followOnProduct.name]: undefined,
            [fields.followOnPlanID.name]: undefined,
          });

        // Reset follow-on payment when follow-on product changes
        const selectedFollowOnProduct = form.getFieldValue(
          fields.followOnProduct.name
        );
        const onFollowOnProductChange = (): void =>
          form.setFieldsValue({
            [fields.followOnPlanID.name]: undefined,
          });

        const selectedFollowOnPlan = eligiblePlans.find(
          (plan) => plan.id === form.getFieldValue(fields.followOnPlanID.name)
        );

        return (
          <>
            {/* Product */}
            <AFormFieldRow>
              <ASelectProductField
                antdForm={form}
                customWidth={{ percent: 70 }}
                enabledProducts={uniq(
                  eligiblePlans.map((plan) => plan.product.type)
                )}
                label={fields.product.label}
                name={fields.product.name}
                onChange={onProductChange}
                required
              />
            </AFormFieldRow>

            {/* Date */}
            <AFormFieldRow>
              <ADateField
                antdForm={form}
                disabled={addAsFollowOnChecked}
                disableDate={
                  assignedCenter
                    ? {
                        filter: disablePastStartDates(assignedCenter.timezone),
                      }
                    : undefined
                }
                initialValue={
                  !assignedCenter
                    ? undefined
                    : addAsFollowOnChecked
                    ? DateTime.fromISO(latestPlan!.endTime!, {
                        zone: assignedCenter.timezone,
                      }).toISODate()
                    : DateTime.fromObject({
                        zone: assignedCenter.timezone,
                      }).toISODate()
                }
                label={fields.startTime.label}
                name={fields.startTime.name}
                required={
                  form.getFieldValue(fields.addAsFollowOn.name) === false
                }
              />
              {showAddAsFollowOn && (
                <CheckboxFieldStyled
                  antdForm={form}
                  initialValue={true}
                  label={fields.addAsFollowOn.label}
                  name={fields.addAsFollowOn.name}
                  onChange={onAddAsFollowOnChange}
                />
              )}
            </AFormFieldRow>

            {/* Payment plan */}
            <AFormFieldRow>
              <ARadioField
                antdForm={form}
                disabled={!selectedProduct}
                label={
                  selectedProduct && selectedProduct.includes('WAITLIST')
                    ? 'Plan'
                    : fields.planID.label
                }
                name={fields.planID.name}
                onChange={onPlanChange}
                radioOptions={
                  selectedProduct
                    ? getPlanPaymentOptions(selectedProduct, eligiblePlans)
                    : [
                        {
                          label: 'Select a care plan',
                          value: 'productDisabled',
                        },
                      ]
                }
                required
                vertical
              />
            </AFormFieldRow>

            {/* Coupon */}
            <AFormFieldRow>
              <ASelectCouponField
                antdForm={form}
                coupons={coupons.map(({ node }) => node)}
                couponsToAlwaysAllow={memberPlans
                  .filter(
                    (planPurchase) =>
                      // Honor coupons used on waitlist even if they're expired
                      planPurchase.node.coupon &&
                      planPurchase.node.plan.product.type.includes('WAITLIST')
                  )
                  .map((planPurchase) => planPurchase.node.coupon!)}
                customWidth={{ percent: 80 }}
                disabled={!selectedPlan}
                filterValues={{
                  centerID: assignedCenter ? assignedCenter.id : undefined,
                  planID: selectedPlan?.id,
                  product: selectedProduct,
                }}
                label={fields.couponSlug.label}
                name={fields.couponSlug.name}
              />
            </AFormFieldRow>

            {/* Follow-on */}
            <FollowOn
              show={
                !(showAddAsFollowOn && addAsFollowOnChecked) &&
                !!selectedPlan &&
                !!selectedPlan.numberOfBillingCycles
              }
            >
              <AFormFieldRow>
                <ARadioField
                  antdForm={form}
                  disabled={!selectedPlan}
                  label={fields.followOnOption.label}
                  name={fields.followOnOption.name}
                  onChange={onFollowOnOptionChange}
                  radioOptions={[
                    {
                      label: 'Care plan auto-renews',
                      value: FollowOnOption.SamePlan,
                    },
                    {
                      label: 'Start a different care plan when this one ends',
                      value: FollowOnOption.NewPlan,
                    },
                    {
                      label: 'No follow-on',
                      value: FollowOnOption.None,
                    },
                  ]}
                  required
                  vertical
                />
              </AFormFieldRow>

              {/* Product */}
              <FollowOn
                show={selectedFollowOnOption === FollowOnOption.NewPlan}
              >
                <AFormFieldRow>
                  <ASelectProductField
                    antdForm={form}
                    customWidth={{ percent: 70 }}
                    enabledProducts={uniq(
                      eligiblePlans.map((plan) => plan.product.type)
                    )}
                    label={fields.followOnProduct.label}
                    name={fields.followOnProduct.name}
                    onChange={onFollowOnProductChange}
                    required={selectedFollowOnOption === FollowOnOption.NewPlan}
                  />
                </AFormFieldRow>

                {/* Payment plan */}
                <AFormFieldRow>
                  <ARadioField
                    antdForm={form}
                    disabled={!selectedFollowOnProduct}
                    label={fields.followOnPlanID.label}
                    name={fields.followOnPlanID.name}
                    radioOptions={
                      selectedFollowOnProduct
                        ? getPlanPaymentOptions(
                            selectedFollowOnProduct,
                            eligiblePlans
                          )
                        : [
                            {
                              label: 'Select the next care plan',
                              value: 'followOnProductDisabled',
                            },
                          ]
                    }
                    required={selectedFollowOnOption === FollowOnOption.NewPlan}
                    vertical
                  />
                </AFormFieldRow>
              </FollowOn>
            </FollowOn>

            {selectedPlan && !selectedProduct.includes('WAITLIST') && (
              <Summary>
                <SummaryTitle>Summary</SummaryTitle>
                <ATextLight>
                  {getPlanPurchaseSummary(
                    selectedPlan,
                    addAsFollowOnChecked
                      ? DateTime.fromISO(latestPlan!.endTime!)
                      : DateTime.fromJSDate(
                          (form.getFieldValue(
                            fields.startTime.name
                          ) as Moment).toDate()
                        ),
                    true,
                    selectedFollowOnOption === FollowOnOption.SamePlan
                  )}{' '}
                  {selectedCoupon && (
                    <>
                      {getPlanPurchaseAmountDue(selectedPlan, selectedCoupon)}
                      <br />
                    </>
                  )}
                  {!(showAddAsFollowOn && addAsFollowOnChecked) &&
                    selectedFollowOnPlan &&
                    'Then ' +
                      getPlanPurchaseSummary(
                        selectedFollowOnPlan,
                        DateTime.fromJSDate(
                          (form.getFieldValue(
                            fields.startTime.name
                          ) as Moment).toDate()
                        ).plus({
                          months: selectedPlan.monthsDuration!,
                        })
                      )}
                </ATextLight>
              </Summary>
            )}

            {selectedPlan &&
              selectedProduct.includes('WAITLIST') &&
              !!selectedCoupon && (
                <ATextLight>
                  Coupon discounts will be applied when the member is enrolled
                  off the waitlist.
                </ATextLight>
              )}
          </>
        );
      }}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const CheckboxFieldStyled = styled(ACheckboxField)`
  &&& {
    margin-left: ${theme.space.l};
    ${theme.layout.breakpointMixin.phoneOnly} {
      margin-left: 0;
    }
  }
`;

const FollowOn = styled.div<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const Summary = styled.div`
  margin-right: ${theme.space.s};
  width: 100%;
`;

const SummaryTitle = styled.div`
  font-weight: ${theme.font.weight.medium};
`;

/** Form field info */
const fields = {
  addAsFollowOn: {
    label: 'Start when current plan ends',
    name: 'addAsFollowOn',
  },
  couponSlug: {
    label: 'Coupon code',
    name: 'couponSlug',
  },
  followOnOption: {
    label: 'Follow-on plan',
    name: 'followOnOption',
  },
  followOnPlanID: {
    label: 'Payment',
    name: 'followOnPlanID',
  },
  followOnProduct: {
    label: 'Next care plan',
    name: 'followOnProduct',
  },
  planID: {
    label: 'Payment',
    name: 'planID',
  },
  product: {
    label: 'Product',
    name: 'product',
  },
  startTime: {
    label: 'Start date',
    name: 'startTime',
  },
};

export { PlanPurchaseCreate, fields, FollowOnOption };
